import React from "react"
import PropTypes from "prop-types";
import ReactPlayer from "react-player"

const Audio = ({ audioSrcURL, ...props  }) => (
    <div className="audio-player">
       <ReactPlayer
       width={"100%"}
       height={"50%"}
       url={audioSrcURL}
        />
    </div>
    )

Audio.propTypes = {
  audioSrcURL: PropTypes.string.isRequired
};

export default Audio