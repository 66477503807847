import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Video from '../components/Video';
import Audio from '../components/Audio';
import FullWidthImage from '../components/FullWidthImage';
import Content, { HTMLContent } from '../components/Content';
import SvgWithImage from '../components/SvgWithImage';
import FamilyTree from '../components/FamilyTree';

// eslint-disable-next-line
export const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  description,
  videoLink,
  audioLink,
  projectImage,
  nameOfArtists,
  responseTo,
  tags,
  relatedPostTitle,
  relatedPostArtists,
  currentArtist,
  helmet,
  relatedPosts,
}) => {
  const PageContent = contentComponent || Content;
  let allRelatedArtists = '';
  relatedPostArtists
    ? (allRelatedArtists = relatedPostArtists.map((item) => item.artist).join(', '))
    : (allRelatedArtists = '');
  return (
    <section className="section">
      {helmet || ''}
      <div className="container content">
        <div className="columns">
          <div className="column">
            <h3 className='project-title'>{title}</h3>
            <div className="article project-content">
              {videoLink && <Video videoSrcURL={videoLink} />}
              {audioLink && <Audio audioSrcURL={audioLink} />}
              <PageContent content={content} />
              {projectImage && <FullWidthImage img={projectImage} title={title} />}
              {description &&
                <div className='project-description'>
                  <div>{description}</div>
                </div>
              }
              {currentArtist?.map(({ node: artist }) => (
                <div className="columns is-centered project-artist" key={artist.id}>
                  {/* <h4>{nameOfArtists.length > 1 ? 'Artists' : 'Artist'}</h4> */}
                  <div className="column is-4 center-blobs-overlay">
                    {artist.frontmatter.artistImage && (
                      <SvgWithImage img={artist.frontmatter.artistImage} width={250} height={250} />
                    )}
                  </div>
                  <div className="column">
                    <div>
                      {/* {nameOfArtists && <h3>{`by ${relatedPostArtists.map((item) => item.artist).join(', ')} `} */}
                      {nameOfArtists && (
                        <h3>
                          {`by ${artist.frontmatter.artistName} `}
                          {artist.frontmatter.artistPronoun && (<span
                            style={{ fontSize: '0.7em' }}
                          >{`( ${artist.frontmatter.artistPronoun} )`}</span>)}
                        </h3>
                      )}
                    </div>
                    <p>{artist.frontmatter.bio}</p>
                  </div>
                </div>
              ))}
              {tags && tags.length ? (
                <div>
                  {/* <h4>Tags</h4> */}
                  <ul className="taglist">
                    {tags?.map((tag) => (
                      <li key={tag + `tag`}>
                        <Link className="pointer" to={`/tags/${kebabCase(tag)}/`}>
                          {tag}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
              <div className="is-mobile is-vcentered family-tree">
                {title?.toLowerCase() !== responseTo && (
                  <div className='family-tree-image'>
                    <FamilyTree relatedPosts={relatedPosts} />
                  </div>
                )}
                <h4>
                  <Link
                    className="hoverWhite pointer"
                    to="/submit"
                    state={{ responseToProject: title }}
                  >
                    respond to this project
                  </Link>
                </h4>
              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  videoLink: PropTypes.string,
  audioLink: PropTypes.string,
  projectImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  nameOfArtists: PropTypes.array,
  artistBio: PropTypes.string,
  artistImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  responseTo: PropTypes.string,
  relatedPostTitle: PropTypes.string,
  relatedPostArtists: PropTypes.array,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object,
  currentArtist: PropTypes.array,
};

const BlogPost = ({ data }) => {
  const { blogPosts: post } = data;
  //slug of related project
  const relatedProjectSlug = '/projects/' + post.frontmatter.responseTo + '/';
  const { edges: allPosts } = data.allMarkdownRemark;

  //related post
  const currentRelatedPost = allPosts.find(({ node: thisPost }) => {
    return thisPost.fields.slug === relatedProjectSlug;
  });
  const { edges: allArtists } = data.artists;
  //all artists
  const thisPostArtists = post.frontmatter.nameOfArtists;
  //all artist of this post
  const currentArtist = allArtists.filter(({ node: thisArtist }) =>
    thisPostArtists.find((item) => item.artist === thisArtist.frontmatter.artistName)
  );

  let currPost = post;
  let relPosts = [];
  //get post data of related post
  const getCloseRelatedPosts = (p) => {
    relPosts = relPosts.concat(
      allPosts.filter(
        // children
        ({ node: item }) =>
          item?.frontmatter.responseTo.toLowerCase() === p?.frontmatter.title.toLowerCase()
      )
    );

    relPosts = relPosts.concat(
      allPosts.filter(
        // current
        ({ node: item }) =>
          item.frontmatter.title.toLowerCase() === p.frontmatter.title.toLowerCase()
      )
    );

    // if (currentPost.frontmatter.title.toLowerCase() != 'community') {
    relPosts = relPosts.concat(
      // parent
      allPosts.filter(
        ({ node: item }) =>
          item.frontmatter.title.toLowerCase() === p.frontmatter.responseTo.toLowerCase()
      )
    );
    // }

    return relPosts;
  };

  getCloseRelatedPosts(currPost);

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        videoLink={post.frontmatter.videoLink}
        audioLink={post.frontmatter.audioLink}
        projectImage={post.frontmatter.projectImage}
        nameOfArtists={post.frontmatter.nameOfArtists}
        responseTo={post.frontmatter.responseTo}
        helmet={
          <Helmet titleTemplate="%s | Project">
            <title>{`${post.frontmatter.title}`}</title>
            <meta name="description" content={`${post.frontmatter.description}`} />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        relatedPostTitle={currentRelatedPost.node.frontmatter.title}
        relatedPostArtists={currentRelatedPost.node.frontmatter.nameOfArtists}
        currentArtist={currentArtist}
        relatedPosts={relPosts}
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___title] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            responseTo
            mediaType
            nameOfArtists {
              artist
            }
          }
        }
      }
    }
    blogPosts: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        videoLink
        audioLink
        projectImage {
          # When gatsby-transformer-sharp cannot process an image (e.g. because it has a GIF format)
          # it copies it into the output directory allowing us to reference it by URL.
          # This "publicURL" attribute will have a value even when childImageSharp does not,
          # and we can use that value to render a normal <img> element instead of a gatsby-image component.
          publicURL
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        nameOfArtists {
          artist
        }
        responseTo
        mediaType
        tags
      }
    }
    artists: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___artistName] }
      filter: { frontmatter: { contentType: { eq: "artist" } } }
    ) {
      edges {
        node {
          id
          frontmatter {
            artistName
            artistPronoun
            bio
            artistImage {
              childImageSharp {
                gatsbyImageData(quality: 100, width: 200)
              }
            }
          }
        }
      }
    }
  }
`;
