import React from "react"
import PropTypes from "prop-types";

const Video = ({ videoSrcURL, ...props }) => {
  const convert_video_link = (input) => {
  let url = videoSrcURL;
   if (url.includes("youtube"))
    { 
      url = convert_youtube(input);
    }
   if (url.includes("vimeo"))
    {
      url = convert_vimeo(input);
    }
    return url;
  }
  const convert_vimeo = (input) => {
    var pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/(?:.*\/)?(.+)/g;
    if (pattern.test(input)) {
      var replacement = '//player.vimeo.com/video/$1';
      var input = input.replace(pattern, replacement);
    }
    return input;
  };
  function convert_youtube(input) {
    var pattern = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(\S+)/g;
    if (pattern.test(input)) {
      var replacement = '//www.youtube.com/embed/$1';
      var input = input.replace(pattern, replacement);
      // For start time, turn get param & into ?
      var input = input.replace('&amp;t=', '?t=');
    }
    return input;
  }
  return (
    <div className="video-responsive">
      <iframe
        src={convert_video_link(videoSrcURL)}
        title="This is the project video"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        frameBorder="0"
        webkitallowfullscreen="true"
        mozallowfullscreen="true"
        allowFullScreen
      />
    </div>
  );
};

Video.propTypes = {
  videoSrcURL: PropTypes.string.isRequired
};

export default Video