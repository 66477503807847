import React from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

export default function FullWidthImage(props) {
  const {img, imgPosition = 'top left' } = props;

  //this only works for JPEG, PNG, WebP and TIFF
  const projectImageUrl = getImage(img) || img;

  // this work for any other format, including GIF
  const projectImageUrlOtherFormats = img.publicURL;

  return (
    <React.Fragment>
      <div
        className="margin-top-0"
        style={{
          display: 'grid',
          alignItems: 'center',
        }}
      >
        {img?.childImageSharp ? (
          <GatsbyImage
            image={projectImageUrl}
            objectFit={'cover'}
            objectPosition={imgPosition}
            style={{
              gridArea: '1/1',
              // You can set a maximum height for the image, if you wish.
            }}
            layout="fullWidth"
            // You can optionally force an aspect ratio for the generated image
            //aspectratio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt="project image"
            formats={['auto', 'webp', 'avif']}
          />
        ) : (
          <img
            src={projectImageUrlOtherFormats}
            objectFit={'cover'}
            objectPosition={imgPosition}
            style={{
              gridArea: '1/1',
              width: '100%',
            }}
            // You can optionally force an aspect ratio for the generated image
            aspectratio={3 / 1}
            // This is a presentational image, so the alt should be an empty string
            alt="project image"
          />
        )}
      </div>
    </React.Fragment>
  );
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
