import React, { useState } from 'react';
import { pack, hierarchy, stratify } from 'd3';
import { navigate } from 'gatsby';
import { window } from 'browser-monads'; //npm i browser-monads

import BlobsSVG1_1 from '../img/svgBlob/level1/1/indexBlobSVG1_1';
import BlobsSVG1_2 from '../img/svgBlob/level1/2/indexBlobSVG1_2';
import BlobsSVG1_3 from '../img/svgBlob/level1/3/indexBlobSVG1_3';
import BlobsSVG1_4 from '../img/svgBlob/level1/4/indexBlobSVG1_4';

import BlobsSVG2_1 from '../img/svgBlob/level2/1/indexBlobSVG2_1';
import BlobsSVG2_2 from '../img/svgBlob/level2/2/indexBlobSVG2_2';
import BlobsSVG2_3 from '../img/svgBlob/level2/3/indexBlobSVG2_3';
import BlobsSVG2_4 from '../img/svgBlob/level2/4/indexBlobSVG2_4';

import BlobsSVG3_1 from '../img/svgBlob/level3/1/indexBlobSVG3_1';
import BlobsSVG3_2 from '../img/svgBlob/level3/2/indexBlobSVG3_2';
import BlobsSVG3_3 from '../img/svgBlob/level3/3/indexBlobSVG3_3';
import BlobsSVG3_4 from '../img/svgBlob/level3/4/indexBlobSVG3_4';

import BlobsSVG4_1 from '../img/svgBlob/level4/1/indexBlobSVG4_1';
import BlobsSVG4_2 from '../img/svgBlob/level4/2/indexBlobSVG4_2';
import BlobsSVG4_3 from '../img/svgBlob/level4/3/indexBlobSVG4_3';
import BlobsSVG4_4 from '../img/svgBlob/level4/4/indexBlobSVG4_4';

import BlobsSVG2 from '../img/svgBlob/level2/indexBlobSVG2';
import BlobsSVG3 from '../img/svgBlob/level3/indexBlobSVG3';
import BlobsSVG4 from '../img/svgBlob/level4/indexBlobSVG4';

const FamilyTree = ({ relatedPosts }) => {
  const heightCanvas = 350;
  const widthCanvas = 350;

  const [isSelected, setSelection] = useState(0);
  const posts = relatedPosts;

  let allPosts = posts?.map((el) => ({
    name: el.node.frontmatter.title.toLowerCase().replace(/ /g, '-'),
    parent: el.node.frontmatter.responseTo.toLowerCase().replace(/ /g, '-'),
    mediaType: el.node.frontmatter.mediaType,
    url: el.node.fields.slug,
    artist: el.node.frontmatter.nameOfArtists[0].artist,
  }));
  if (!allPosts) {
    return null;
  } else {
    allPosts[allPosts.length - 1] = {
      artist: allPosts[allPosts.length - 1].artist,
      name: allPosts[allPosts.length - 1].name,
      parent: '',
      mediaType: 'text',
      url: allPosts[allPosts.length - 1].url,
    };

    var root = stratify()
      .id(function (d) {
        return d.name;
      })
      .parentId(function (d) {
        return d.parent;
      })(allPosts);
  }
  const dataP = hierarchy(root).sum(() => 1);
  const createPack = pack()
    .size([widthCanvas - 50, heightCanvas - 50])
    .padding(130);
  const projectPack = createPack(dataP);

  const allRadius = projectPack.descendants().map((el) => {
    return el.r;
  });

  const uniqueRadius = [...new Set(allRadius)];
  uniqueRadius.sort((a, b) => a - b);

  const allMediaTypes = allPosts.map((el) => {
    return el.mediaType;
  });

  const uniqueMediaTypes = [...new Set(allMediaTypes)];

  const values1 = Object.values(BlobsSVG1_1);
  const values2 = Object.values(BlobsSVG2);
  const values3 = Object.values(BlobsSVG3);
  const values4 = Object.values(BlobsSVG4);
  const mediaOther = [values1, values2, values3, values4];

  const valuesImg1 = Object.values(BlobsSVG1_2);
  const valuesImg2 = Object.values(BlobsSVG2);
  const valuesImg3 = Object.values(BlobsSVG3);
  const valuesImg4 = Object.values(BlobsSVG4);
  const image = [valuesImg1, valuesImg2, valuesImg3, valuesImg4];

  const valuesText1 = Object.values(BlobsSVG1_3);
  const valuesText2 = Object.values(BlobsSVG2);
  const valuesText3 = Object.values(BlobsSVG3);
  const valuesText4 = Object.values(BlobsSVG4);
  const text = [valuesText1, valuesText2, valuesText3, valuesText4];

  const valuesAudio1 = Object.values(BlobsSVG1_4);
  const valuesAudio2 = Object.values(BlobsSVG2);
  const valuesAudio3 = Object.values(BlobsSVG3);
  const valuesAudio4 = Object.values(BlobsSVG4);
  const audio = [valuesAudio1, valuesAudio2, valuesAudio3, valuesAudio4];

  function getRandomBlobSVG(i, r, mediaType) {
    let url;
    let blobArray;
    if (mediaType === uniqueMediaTypes[0]) {
      blobArray = [...image];
    } else if (mediaType === uniqueMediaTypes[1]) {
      blobArray = [...text];
    } else if (mediaType === uniqueMediaTypes[2]) {
      blobArray = [...audio];
    } else {
      blobArray = [...mediaOther];
    }

    switch (r) {
      case uniqueRadius[0]:
        url = blobArray[0][i % blobArray[0].length];
        break;
      case uniqueRadius[1]:
        url = blobArray[1][i % blobArray[1].length];
        break;
      case uniqueRadius[2]:
        url = blobArray[2][i % blobArray[2].length];
        break;
      case uniqueRadius[3]:
        url = blobArray[3][i % blobArray[3].length];
        break;
      default:
        url = blobArray[3][i % blobArray[3].length];
    }
    return url;
  }

  function getRandomRotation(i) {
    return (i * 10) % 360;
  }

  let isMobile = false;
  if (window.width < 500) isMobile = true;

  let clicks = 0;
  const DELAY = 600;
  let timer = null;

  const handleClickEvent = (event) => {
    clicks++;
    if (clicks === 1) {
      // setClicked(1);
      timer = setTimeout(() => {
        handleSingleClick(event.target.attributes[0].nodeValue);
        clicks = 0; //after action performed, reset counter
      }, DELAY);
    } else {
      clearTimeout(timer); //prevent single-click action

      handleDoubleClick(event.target.attributes[0].nodeValue);
      clicks = 0; //after action performed, reset counter
    }
  };

  const handleSingleClick = (url) => {
    if (isMobile) {
    } else {
      navigate(url);
    }
  };

  const handleDoubleClick = (url) => {
    navigate(url);
  };

  return (
    <svg className={`bubbleSvg mobile`} width={heightCanvas} height={widthCanvas}>
      <defs>
        <filter id="greyscale">
          <feColorMatrix
            type="matrix"
            values="0.33 0.33 0.33 0 0
                             0.33 0.33 0.33 0 0
                             0.33 0.33 0.33 0 0
                             0 0 0 1 0"
          />
        </filter>
        <filter id="noiseDisp" colorInterpolationFilters="sRGB">
          <feTurbulence
            baseFrequency={0.01}
            numOctaves="3"
            seed={200}
            type="fractalNoise"
            result="turbulence"
          />
          <feColorMatrix in="turbulence" type="hueRotate" values="0" result="turbulenceAnimated">
            <animate attributeName="values" from="0" to="360" dur="7s" repeatCount="indefinite" />
          </feColorMatrix>
          <feDisplacementMap
            type="displaced"
            in="SourceGraphic"
            in2="turbulenceAnimated"
            xChannelSelector="R"
            yChannelSelector="G"
            scale={25}
            result="displaced"
          />
          <feGaussianBlur in="displaced" stdDeviation="6" result="blurred" />
          <feMerge>
            <feMergeNode in="blurred" />
            <feMergeNode in="displaced" />
          </feMerge>
        </filter>
      </defs>

      {projectPack?.descendants().map(({ x, y, r, data }, i, arr) => (
        <g className="circleContainer" key={data.id}>
          <g
            transform={`translate(${x},${y})`}
            className="blobContainer"
            onMouseOver={() => setSelection(data.data.url)}
            onMouseLeave={() => setSelection(0)}
            style={
              isSelected === data.data.url && isMobile === false
                ? { filter: 'url(#noiseDisp)' }
                : { filter: 'none' }
            }
          >
            <image
              className="blob-image"
              x={-r}
              y={-r}
              width={r * 2}
              height={r * 2}
              href={getRandomBlobSVG(i, r)}
              style={i === 1 ? { filter: 'url(#greyscale)' } : { filter: 'none' }}
              transform={'rotate(' + getRandomRotation(i) + ')'}
            />
            {isSelected === data.data.url && (
              <image
                url={data.data.url}
                onClick={handleClickEvent}
                className="blob-image"
                x={-r}
                y={-r}
                width={r * 2}
                height={r * 2}
                href={getRandomBlobSVG(i, r)}
                transform={'rotate(' + getRandomRotation(i) + ')'}
                opacity="0.5"
              />
            )}
          </g>
          {isSelected === data.data.url && (
            <g className="label" transform={`translate(${x + r / 1.5},${y})`}>
              <foreignObject x={0} y={0} width="100" height="200">
                <p
                  className="textLabel"
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    fontSize: `1.25rem`,
                    frontWeight: `bold`,
                  }}
                >
                  {arr[i].data.data.name}
                </p>
                <p
                  className="textLabel"
                  xmlns="http://www.w3.org/1999/xhtml"
                  style={{
                    fontSize: `1rem`,
                    transform: `translate(0,-12px)`,
                  }}
                >
                  {arr[i].data.data.artist}
                </p>
              </foreignObject>
            </g>
          )}
        </g>
      ))}
    </svg>
  );
};

export default FamilyTree;
